import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';

const formatDate = ({ date, format: dateFormat = 'd MMMM y' }) => {
  const isoDate = parseISO(date);
  if (isoDate.toString() === 'Invalid Date') {
    return '';
  }
  return (
    format(parseISO(date), dateFormat, {
      locale: nl,
    }) || ''
  );
};

export default formatDate;
